import FormFooter from "./formFooter";
import bannerLogo from '../../assets/images/GBGC_Login_logo.png';
import './nominationForm.css';
import ImageCropper from "../global/imageCropper";
import { useState, useContext } from "react";
import { signatureContext } from "../../context/context";
import SignatureModal from "./signatureModal";

const NominationForm = (props) => {

    const {setAccountsPhotoBlob, accountsPhotoBlob, setMembershipPhotoBlob, membershipPhotoBlob, setAdminPhotoBlob, adminPhotoBlob, setPresidentPhotoBlob, presidentPhotoBlob, updateGroupDetails, groupDetails, adminDetails, updateAdminDetails, primaryDetails, accessDetails, count, adminSignature} = props;

    const [billingCropperData,          setBillingCropperData]         = useState(null);
    const [membershipCropperData,       setMembershipCropperData]      = useState(null);
    const [accountsCropperData,         setAccountsCropperData]        = useState(null);
    const [presidentCropperData,        setPresidentCropperData]       = useState(null);
    const [showModal,                   setShowModal]                  = useState(false);
    const [turn,                        setTurn]                       = useState(null);

     const signatureImg = useContext(signatureContext)
 
    const openBillingSignatureCropper = async(e) =>{
        e.preventDefault()
        $('#modalSignature').addClass('modal-blur');
        setBillingCropperData({
            imageRatio  : { width : 130, height : 35},
            dataHandler : (val) =>  setAccountsPhotoBlob(val),
            header : 'Upload Signature'
        })
    };
    const addAdminSignatureName = async(name) =>{
        setShowModal(true);
        setTurn(name); 
    };

    const removeBillingSignatureHandler = async(e) => {
		e.preventDefault();
		setAccountsPhotoBlob(null)
        updateAdminDetails('AccountSignature',  '')

	};

    const openMembershipSignatureCropper = async(e) =>{
        e.preventDefault()
        $('#modalSignature').addClass('modal-blur');
        setMembershipCropperData({
            imageRatio  : { width : 130, height : 35},
            dataHandler : (val) =>  setMembershipPhotoBlob(val),
            header : 'Upload Signature'
        })
    };

    const removeMembershipSignatureHandler = async(e) => {
		e.preventDefault();
		setMembershipPhotoBlob(null)
        updateAdminDetails('MembershipSignature',  '')

	};

    const openAccountsSignatureCropper = async(e) =>{
        e.preventDefault()
        $('#modalSignature').addClass('modal-blur');
        setAccountsCropperData({
            imageRatio  : { width : 130, height : 35},
            dataHandler : (val) =>  setAdminPhotoBlob(val),
            header : 'Upload Signature'
        })
    };

    const removeAdminSignatureHandler = async(e) => {
		e.preventDefault();
		setAdminPhotoBlob(null)
        updateAdminDetails('AdminSignature',  '')

	};

    const openPresidentSignatureCropper = async(e) =>{
        e.preventDefault()
        $('#modalSignature').addClass('modal-blur');
        setPresidentCropperData({
            imageRatio  : { width : 130, height : 35},
            dataHandler : (val) =>  setPresidentPhotoBlob(val),
            header : 'Upload Signature'
        })
    };

    const removePresidentSignatureHandler = async(e) => {
		e.preventDefault();
		setPresidentPhotoBlob(null)
        updateAdminDetails('PresidentSignature',  '')

	};
    return(
        <>
            <div className="panel panel-paper">
                <div className="panel-body form-onboarding">
                    <div className="panel-heading text-center mb-20">
                        {/* Logo and club name */}
                        <img className="img-responsive img-login margin-b10 banner-logo" src={bannerLogo} alt="" />
                        <h3 className="panel-title mb-0 padding-t0"><strong>THE GAEKWAD BARODA GOLF CLUB, MEMBERSHIP AGREEMENT</strong></h3>
                    </div>
                    <fieldset className="primary">
                        <legend> </legend>
                        <div>
                            <h5><strong>Note: Documents to be self-attested and submitted along with duly filled membership Agreement.</strong></h5>
                            <h4><strong>NOMINATION:</strong></h4>
                            <p className="agreement-document flex-wrap">
                                IN THE EVENT OF MY DEATH, MR./MRS./ &#160;&#160;
                                <input type="text" className="form-control wide-input" id="inputFirstName" disabled value={ primaryDetails?.FirstName && primaryDetails?.LastName ?  primaryDetails.FirstName + ' ' + primaryDetails.LastName : ''} />
                                WILL BE TREATED AS MY NOMINEE AND BE ENTITLED TO GET THE REFUND OF INTEREST-FREE SECURITY DEPOSIT AFTER DEDUCTING ALL THE OUTSTANDING DUES IF ANY.
                                <br />
                                I ACCEPT ALL ABOVE TERMS & CONDITIONS OF CLUB MEMBERSHIP AGREEMENT.
                            </p>
                            <div className="agreement-document flex-center">
                                APPLICANT’S SIGN:
                                {(!!signatureImg) ? (
                                    <div className="avatar__holder"
                                        style={{
                                            backgroundImage: typeof signatureImg === 'object' && signatureImg instanceof Blob
                                                ? `url(${URL.createObjectURL(signatureImg)})`
                                                : `url(${signatureImg})`,
                                            width: "130px",
                                            height: "30px",
                                            marginLeft: '23px',
                                            backgroundSize: "cover",
                                            backgroundPosition: "center"
                                        }}
                                    ></div>
                                ) : (
                                    <div style={{ width: "130px", height: "30px"}}></div>
                                )}                                 
                                <span className="ml-23">DATE:&#160;&#160;&#160;</span>
                                <input type="date" className="form-control date-input" id="inputDate" name="date" value={groupDetails.Date ? moment(groupDetails.Date).format('YYYY-MM-DD') : ''} onChange={(event) => updateGroupDetails('Date', event.target.value)} />
                            </div>
                            <div className={`${accessDetails.AdminID == 0 ? 'disabled-overlay' : ''}`}>
                                <h4><strong>FOR ADMINISTRATIVE USE ONLY</strong></h4>
                                <div className="agreement-document flex-center">
                                    FORM CHECKED & VERIFIED BY: 
                                    <div className="padding-l15 flex-center ml-10">
                                        {(adminDetails.AccountSignature || accountsPhotoBlob) ? (
                                           <div className="signature-text" >
                                                <div className="avatar__holder" style={{ backgroundImage: `url(${accountsPhotoBlob ? URL.createObjectURL(accountsPhotoBlob) : adminDetails.AccountSignature})`, width: "130px", height: "35px" , backgroundSize: "cover", backgroundPosition: "center" }}>
                                                {((!!accountsPhotoBlob || adminDetails.AccountSignature) && (accessDetails.AdminType == 'Accounts' || accessDetails.AdminID == 7)) && (
                                                    <span className="nomi-Sign__cancel_bill" data-toggle="kt-tooltip" data-original-title="Cancel avatar" onClick={removeBillingSignatureHandler}>
                                                        <div className="pencil-container ">
                                                            <i className="fa fa-times"></i>
                                                            <span className="add-text">Delete</span>
                                                        </div>
                                                    </span>
                                                )}
                                                </div>
                                           </div>
                                        ) : (
                                            <button type="button" className="btn btn-default upload-button" onClick={() => addAdminSignatureName('Janu Gandhi')} disabled={accessDetails.AdminID != 7 } >Signature</button>
                                        )} 
                                        <span className="ml-23">DATE:&#160;&#160;&#160;</span>
                                        <input type="date" className="form-control date-input" style={{width: "200px"}} id="inputDate" name="Date" value={adminDetails.AccountDate ? moment(adminDetails.AccountDate).format('YYYY-MM-DD') : ''} onChange={(event) => updateAdminDetails('AccountDate', event.target.value)} />
                                    </div>
                                </div>
                                <p className="agreement-document">RECEIPT NO(S):</p>
                                <ul className="agreement-document no-list-style">
                                    <li className="inline-block">1. <input className="text-center" type="text" value={adminDetails.ReceiptNo1 || ''}  onChange={(event) => updateAdminDetails('ReceiptNo1', event.target.value)}/></li>
                                    <li className="inline-block">2. <input className="text-center" type="text" value={adminDetails.ReceiptNo2 || ''}  onChange={(event) => updateAdminDetails('ReceiptNo2', event.target.value)}/></li>
                                    <li className="inline-block">3. <input className="text-center" type="text" value={adminDetails.ReceiptNo3 || ''}  onChange={(event) => updateAdminDetails('ReceiptNo3', event.target.value)}/></li>
                                    <li className="inline-block">4. <input className="text-center" type="text" value={adminDetails.ReceiptNo4 || ''}  onChange={(event) => updateAdminDetails('ReceiptNo4', event.target.value)}/></li>
                                </ul>
                                <p className="agreement-document">TOTAL CHEQUES RECEIVED: <input className="text-center" type="text" value={adminDetails.TotalCheques || ''}  onChange={(event) => updateAdminDetails('TotalCheques', event.target.value)} /></p>
                                <p className="agreement-document">CARDS DISPATCHED ON: <input className="text-center" type="text" value={adminDetails.CardsDispatchedOn || ''}  onChange={(event) => updateAdminDetails('CardsDispatchedOn', event.target.value)}/></p>
                                <div className="flex-space-between pt-80">
                                    <div className="agreement-document">
                                        <div className="flex-space-between align-center">
                                            <p className="margin-b0">CHECKED BY: &#160;</p>
                                             {(adminDetails.MembershipSignature || membershipPhotoBlob) ? (
                                                <div className="signature-text" >
                                                    <div className="avatar__holder" style={{ backgroundImage: `url(${membershipPhotoBlob ? URL.createObjectURL(membershipPhotoBlob) : adminDetails.MembershipSignature && adminDetails.MembershipSignature })`, backgroundSize: "cover", backgroundPosition: "center" }}>
                                                        {((!!membershipPhotoBlob || adminDetails.MembershipSignature) && (accessDetails.AdminType == 'Membership' || accessDetails.AdminID == 8)) && (
                                                            <span className="nomi-Sign__cancel_bill" data-toggle="kt-tooltip" data-original-title="Cancel avatar" onClick={removeMembershipSignatureHandler}>
                                                                <div className="pencil-container">
                                                                    <i className="fa fa-times"></i>
                                                                    <span className="add-text">Delete</span>
                                                                </div>
                                                            </span>
                                                            
                                                        )}
                                                    </div>
                                                </div>
                                            ) : (
                                                <button type="button" className="btn btn-default upload-button" onClick={() => addAdminSignatureName('Jessy Pothen')} disabled={accessDetails.AdminID != 8 || !adminDetails.AccountSignature}> Signature</button>
                                            )} 
                                        </div>
                                        <p className="padding-l30 margin-b0">MEMBERSHIP DEPARTMENT</p>
                                    </div>
                                    <div className="agreement-document">
                                        <div className="padding-l10">
                                            {(adminPhotoBlob || adminDetails.AdminSignature) ? (
                                                <div className="signature-text" >
                                                    <div className="avatar__holder" style={{ backgroundImage: `url(${adminPhotoBlob ? URL.createObjectURL(adminPhotoBlob) : adminDetails.AdminSignature && adminDetails.AdminSignature })`, backgroundSize: "cover", backgroundPosition: "center" }}>                                                {((!!adminPhotoBlob || adminDetails.AdminSignature) && (accessDetails.AdminType == 'Admin'  || accessDetails.AdminID == 5)) && (
                                                        <span className="nomi-Sign__cancel_account" data-toggle="kt-tooltip" data-original-title="Cancel avatar" onClick={removeAdminSignatureHandler}>
                                                            <div className="pencil-container">
                                                                <i className="fa fa-times"></i>
                                                                <span className="add-text">Delete</span>
                                                            </div>
                                                        </span>
                                                        )}
                                                    </div>
                                                </div>
                                            ) : (
                                                <button type="button" className="btn btn-default upload-button" onClick={() => addAdminSignatureName('Nitin Pujari')} disabled={accessDetails.AdminID != 5 || !adminDetails.MembershipSignature}> Signature</button>
                                            )} 
                                        </div>
                                        <p>ACCOUNTS DEPARTMENT</p>
                                    </div>
                                </div>
                                <div className="president-section pt-80 ">
                                    {(presidentPhotoBlob || adminDetails.PresidentSignature) ? (
                                        <div className="signature-text" >
                                            <div className="avatar__holder" style={{ backgroundImage: `url(${presidentPhotoBlob ? URL.createObjectURL(presidentPhotoBlob) : adminDetails.PresidentSignature && adminDetails.PresidentSignature })`, marginLeft: "-5px", backgroundSize: "cover", backgroundPosition: "center" }}>
                                            {((!!presidentPhotoBlob || adminDetails.PresidentSignature) && (accessDetails.AdminType == 'President' || adminDetails.AdminID == 28)) && (
                                                <span className="nomi-Sign__cancel_account" data-toggle="kt-tooltip" data-original-title="Cancel avatar" onClick={removePresidentSignatureHandler}>
                                                    <div className="pencil-container">
                                                        <i className="fa fa-times"></i>
                                                        <span className="add-text">Delete</span>
                                                    </div>
                                                </span>
                                                
                                                )}
                                            </div>
                                        </div>
                                    ) : (
                                        <button type="button" className="btn btn-default upload-button margin-l15" onClick={() => addAdminSignatureName('Samarjitsinh Gaekwad')} disabled={accessDetails.AdminID != 28 || !adminDetails.AdminSignature}> Signature</button>
                                    )} 
                                    <p className="padding-l20">P R E S I D E N T</p>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <FormFooter num={count}/>
            </div>
            {billingCropperData && (
                <ImageCropper
                    show           = { billingCropperData }
                    header         = { billingCropperData.header }   
                    imageRatio     = { billingCropperData.imageRatio }
                    dataHandler    = { billingCropperData.dataHandler }
                    onDismissModal = {() => setBillingCropperData(null) }
                />
            )}
            {membershipCropperData && (
                <ImageCropper
                    show           = { membershipCropperData }
                    header         = { membershipCropperData.header }   
                    imageRatio     = { membershipCropperData.imageRatio }
                    dataHandler    = { membershipCropperData.dataHandler }
                    onDismissModal = {() => setMembershipCropperData(null) }
                />
            )}
            {accountsCropperData && (
                <ImageCropper
                    show           = { accountsCropperData }
                    header         = { accountsCropperData.header }   
                    imageRatio     = { accountsCropperData.imageRatio }
                    dataHandler    = { accountsCropperData.dataHandler }
                    onDismissModal = {() => setAccountsCropperData(null) }
                />
            )}
            {presidentCropperData && (
                <ImageCropper
                    show           = { presidentCropperData }
                    header         = { presidentCropperData.header }   
                    imageRatio     = { presidentCropperData.imageRatio }
                    dataHandler    = { presidentCropperData.dataHandler }
                    onDismissModal = {() => setPresidentCropperData(null) }
                />
            )}
             {showModal && (
                <SignatureModal
                    show           = { showModal } 
                    onDismissModal = {() => setShowModal(false) }
                    adminSignature = {adminSignature}
                    setSignaturePhotoBlob = {turn === 'Janu Gandhi' ? setAccountsPhotoBlob : (turn === 'Jessy Pothen' ? setMembershipPhotoBlob : turn === 'Nitin Pujari' ? setAdminPhotoBlob : setPresidentPhotoBlob)}
                    openSignatureCropper = {turn === 'Janu Gandhi' ? openBillingSignatureCropper : (turn === 'Jessy Pothen' ? openMembershipSignatureCropper : turn === 'Nitin Pujari' ? openAccountsSignatureCropper : openPresidentSignatureCropper)}
                    signatureType = {turn === 'Janu Gandhi' ? "AccountSignature" : (turn === 'Jessy Pothen' ? 'MembershipSignature' : turn === 'Nitin Pujari' ? "AdminSignature" : "PresidentSignature")}
                    updateAdminDetails = {updateAdminDetails}
                />
            )}
        </>
    )
};

export default NominationForm; 