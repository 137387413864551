import { useEffect, useRef, useState } from "react";
import bannerLogo from '../../assets/images/GBGC_Login_logo.png';
import ImageCropper from '../global/imageCropper';
import * as helper from "../global/helper";
import './membershipForm.css';
import AddDocument from "../global/addDocument";
import agreementFromList from './agreementFroms.js'; 
import FormFooter from './formFooter.js'; 
import AnnualSubscription from './annualSubscription.js'; 
import PrimaryForm from "./primaryForm.js";
import MemberForm from "./memberForm.js";
import Reference from "./reference.js";
import NominationForm from "./nominationForm.js";
import MembershipPlan from "./membershipPlan.js";
import swal from 'sweetalert2';
import Swal from 'sweetalert2';
import { convertImageToBase64, getOnboardingDataByGroupID, postOnboardingData, uploadPrivateFile, uploadPublicFile } from "../api/onboardingAPI.js";
import { useHistory } from 'react-router-dom';
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import pdfLogo from '../../assets/images/ic_pdf.png';

const MembershipForm = (props) => {
  
    const printRef = useRef();
    const tooltipRef = useRef();

    const [primaryDetails,              setPrimaryDetails]              = useState({MemberType: 'Primary'});
    const [primaryPhotoBlob,            setPrimaryPhotoBlob]            = useState(null);
    const [signaturePhotoBlob,          setSignaturePhotoBlob]          = useState(null);

    const [spouseDetails,               setSpouseDetails]               = useState({MemberType: 'Spouse'});
    const [spousePhotoBlob,             setSpousePhotoBlob]             = useState(null);
    
    const [groupDetails,                setGroupDetails]                = useState({});

    const [memberList,                  setMemberList]                  = useState(Array(groupDetails?.Child).fill({MemberType: 'Child'}));
    const [memberPhotoBlobs,            setMemberPhotoBlobs]            = useState(Array(groupDetails?.Child).fill(null));

    const [reference1Details,           setReference1Details]           = useState({});
    const [reference2Details,           setReference2Details]           = useState({});

    const [adminDetails,                setAdminDetails]                = useState({});

    const [accountsPhotoBlob,           setAccountsPhotoBlob]           = useState(null);
    const [membershipPhotoBlob,         setMembershipPhotoBlob]         = useState(null);
    const [adminPhotoBlob,              setAdminPhotoBlob]              = useState(null);
    const [presidentPhotoBlob,          setPresidentPhotoBlob]          = useState(null);
    
    const [paymentDetails,              setPaymentDetails]              = useState({});
    const [paymentList,                 setPaymentList]                 = useState([]);
    const [editIndex,                   setEditIndex]                   = useState(null);

    const [cropperData,                 setCropperData]                 = useState(null);
    const [signatureCropperData,        setSignatureCropperData]        = useState(null);
    const [addDocument,                 setAddDocument]                 = useState(false);
    const [editDocument,                setEditDocument]                = useState(null);
    const [documentDetails,             setDocumentDetails]             = useState({});
    const [document,                    setDocument]                    = useState([]);
    const [formValidList ,              setFormValidList]               = useState([]);
    const [accessDetails,               setAccessDetails]               = useState({});
    const [panError,                    setPanError]                    = useState('');
    const [urlUserID,                   setUrlUserID]                   = useState('');
    const [urlID,                       setUrlID]                       = useState('');
    const [turn,                        setTurn]                        = useState('User');
    const [adminSignature,              setAdminSignature]              = useState([]);

    const history = useHistory();

    useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search);
        const groupIDValue = queryParameters.get("id");
        let userIDValue = queryParameters.get("userID");

        if (groupIDValue && userIDValue) {
            setUrlID(groupIDValue)
            setUrlUserID(userIDValue)
            fetchOnboardingDetails(groupIDValue, userIDValue, true);
            queryParameters.delete("userID"); 
        } else {
            Swal.close();
            Swal.fire({
                icon: "error",
                titleText: "Error: Form not found",
                showConfirmButton: false,
                allowOutsideClick: false
            });
        }
    }, [history]);

    useEffect(() => {
        if(Object.keys(documentDetails).length != 0){
            setDocument((a) => {
                const filterA = documentDetails.Index  >= 0 ? a.filter((c, i) => i != documentDetails.Index) : a;
                const newA = [...filterA];
                newA.push((documentDetails))
                return newA
            })
        }
    },[documentDetails])

    useEffect(() => {
        if (formValidList.length > 0 && groupDetails?.IsSubmit == "N") {
            const interval = setInterval(() => {
                if (formValidList.length > 0) {
                    saveForm(); // No event passed
                } else {
                    clearInterval(interval);
                }
            }, 15000);
    
            return () => clearInterval(interval); // Cleanup interval
        }
    }, [formValidList]);
     
    useEffect(() => {
        updateFormValidity(); 
    }, [groupDetails, primaryDetails, spouseDetails, memberList, primaryPhotoBlob, spousePhotoBlob, memberPhotoBlobs, document, panError, reference1Details, reference2Details, signaturePhotoBlob]);
 
    useEffect(() => {
        if (groupDetails.PanNo) {
          const upperCasePanNo = groupDetails.PanNo.toUpperCase();
          if (validatePAN(upperCasePanNo)) {
            setPanError('');
          } else {
            setPanError('Invalid PAN card. Please enter a valid PAN.');
          }
        } else {
          setPanError('');
        }
      }, [groupDetails.PanNo]);
   
    const validatePAN = (pan) => {
        const panPattern = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
        return panPattern.test(pan);
    };    

    const fetchOnboardingDetails = async (groupID, userID, isLoader) => {
         try {
            if(isLoader){
                Swal.fire({
                    titleText           : 'Loading',
                    text                : 'Please Wait...',
                    showConfirmButton   : false,
                    allowOutsideClick   : false,
                    onOpen: () => {
                        Swal.showLoading();
                    }
                });
            }
             const response = await getOnboardingDataByGroupID(groupID, userID);

            const primaryUser = response?.User.find(f => f.MemberType === "Primary") || {MemberType: 'Primary'};
            const spouseUser = response?.User.find(f => f.MemberType === "Spouse") || {MemberType: 'Spouse'};
            const memberList = response?.User.filter(({ MemberType }) => MemberType === "Child");
            const childList = memberList.length ? memberList : Array(response?.Group?.Child || 0).fill({ MemberType: 'Child' });
            props.setSignatureImg(response?.Group?.MemberSignature)
            setPrimaryDetails(primaryUser);
            setSpouseDetails(spouseUser);
            setMemberList(childList);
            setReference1Details(response?.Reference[0] || {});
            setReference2Details(response?.Reference[1] || {});
            setAdminDetails(response?.Admin || {});
            setPaymentList(response?.Payment);
            setGroupDetails(response?.Group);
            setDocument(response?.Document);
            setAccessDetails(response?.Access);
            setAdminSignature(response?.AdminSign);
            if(response?.Admin?.PresidentSignature){
                setTurn('President')
            }
            ///
            if(isLoader){
                Swal.close();
            }
        } catch (err) {
            Swal.close();
            Swal.fire({
                icon              : "error",
                titleText         : err.message,
                showConfirmButton : false,
                allowOutsideClick : false
            })
            return;  
        }
    }

    /////////////////////////////////////////////////////////////////// --FormValidator--///////////////////////////////////////////////////////////////////

    const getEmptyFieldsForObject = (obj, requiredFields, prefix = '') => { // Function to get empty fields for an object based on required fields
        const emptyFields = [];
        requiredFields.forEach(field => {
            if (!obj[field]) {
                emptyFields.push(prefix + field);
            }
        });
        return emptyFields;
    };

    const getEmptyFields = () => { // Function to check if all form fields are empty and return list of empty fields
        const emptyFields = [];

        if(panError != ''){
            emptyFields.push(panError)
        }
        // Check groupDetails
        const groupDetailsRequiredFields = [ 'Address'];
        emptyFields.push(...getEmptyFieldsForObject(groupDetails, groupDetailsRequiredFields));
        if (groupDetails.Category === 'Organization' && !groupDetails.CompanyName) {
            emptyFields.push('Company Name');
        }
        if (!groupDetails.MemberSignature && !signaturePhotoBlob) {
            emptyFields.push('Signature');
        }

        // Check primaryDetails
        const primaryDetailsRequiredFields = ['FirstName', 'LastName', 'DOB', 'Gender', 'Email', 'Mobile', 'BloodGroup'];
        emptyFields.push(...getEmptyFieldsForObject(primaryDetails, primaryDetailsRequiredFields));
        if (!primaryDetails.ProfilePhoto && !primaryPhotoBlob) {
            emptyFields.push('Primary Details: Profile Photo');
        }

        // Check spouseDetails
        if(groupDetails.Spouse == "Y"){
            const spouseDetailsRequiredFields = ['FirstName', 'LastName', 'DOB', 'Gender', 'Email', 'Mobile', 'BloodGroup'];
            emptyFields.push(...getEmptyFieldsForObject(spouseDetails, spouseDetailsRequiredFields,'Spouse: '));
            if (!spouseDetails.ProfilePhoto && !spousePhotoBlob) {
                emptyFields.push('Spouse Details: Profile Photo');
            }
        };

        // Check memberList
        memberList.forEach((member, index) => {
            const memberRequiredFields = ['FirstName', 'LastName', 'DOB', 'Gender', 'Email', 'BloodGroup'];
            const memberEmptyFields = getEmptyFieldsForObject(member, memberRequiredFields, `Child ${index + 1}: `);
            emptyFields.push(...memberEmptyFields);
            if (!member.ProfilePhoto && !memberPhotoBlobs[index]) {
                emptyFields.push(`Child ${index + 1}: Profile Photo`);
            }
        });

        // Check spouseDetails
        const referenceFields = ['Name', 'Email', 'Mobile'];
        emptyFields.push(...getEmptyFieldsForObject((reference1Details?.Name ? reference1Details : reference2Details), referenceFields,'Reference Details: '));

        // Custom validation for document array
        if (!document || document.length === 0) {
            emptyFields.push('Document');
        }

        return emptyFields;
    };

    // Function to update form validity state
    const updateFormValidity = () => {
        const emptyFields = getEmptyFields();
        setFormValidList(emptyFields);
    };

    /////////////////////////////////////////////////////////////////// --FormValidator--///////////////////////////////////////////////////////////////////
 
    const removeDocHandler = async(index) => {
        setDocument((a) => {
            const nA = a.filter((b, i) => index !== i);
            return nA;
        });
	};  

    const updatePrimaryDetails = (field, value) => { 
        setPrimaryDetails((d) => {
            return { ...d, [field]: value };
        });
    };

    const updateSpouseDetails = (field, value) => { 
        setSpouseDetails((d) => {
            return { ...d, [field]: value };
        });
    };

    const updateReference1Details = (field, value) => { 
        setReference1Details((d) => {
            return { ...d, [field]: value };
        });
    };

    const updateReference2Details = (field, value) => { 
        setReference2Details((d) => {
            return { ...d, [field]: value };
        });
    };

    const updateAdminDetails = (field, value) => { 
        setAdminDetails((d) => {
            return { ...d, [field]: value };
        });
    };

    const updateGroupDetails = (field, value) => { 
        setGroupDetails((d) => {
            return { ...d, [field]: value };
        });
    };
 
    // Function to update state member by index
    const updateArrayState = (setStateFunc, index, key, newValue) => {
        setStateFunc(prevState => {
            const newState = [...prevState];
            newState[index] = { ...newState[index], [key]: newValue, Index: index };
            return newState;
        });
    };

    // function to update photo state by index
    const updateArrayStatePhoto = (setStateFunc, index, newValue) => {
        setStateFunc(prevState => {
            const newState = [...prevState];
            newState[index] = {newValue, Index: index};
            return newState;
        });
    };

    const updatePaymentDetails = (field, value) => { 
        setPaymentDetails((d) => {
            return { ...d, [field]: value };
        });
    };
    
    const handleAddClick = (e) => {
        e.preventDefault()
        if(paymentDetails.Type == ('Cheque' || 'NEFT') && !paymentDetails?.ReferenceNumber && !paymentDetails?.Date && !paymentDetails?.INR && !paymentDetails?.Bank) return
        if(paymentDetails.Type == ('Card' || 'RTGS') && !paymentDetails?.ReferenceNumber && !paymentDetails?.Date && !paymentDetails?.INR ) return
        if(paymentDetails.Type == ('Cash') && !paymentDetails?.Date && !paymentDetails?.INR ) return
        
        if (editIndex !== null) {
            const updatedList = paymentList.map((item, index) =>
                index === editIndex ? paymentDetails : item
            );
            setPaymentList(updatedList);
            setEditIndex(null);
        } else {
            setPaymentList([...paymentList, paymentDetails]);
        }
        setPaymentDetails({});
    };

    const handleEditClick = (index, e) => {
        e.preventDefault();
        setEditIndex(index);
        setPaymentDetails(paymentList[index]);
    };

    const handleDeleteClick = (index, e) => {
        e.preventDefault();
        const updatedList = paymentList.filter((_, i) => i !== index);
        setPaymentList(updatedList);
    };

    const saveForm = async(e) => {
        let isLoader = formValidList.length > 0 ? false : true

        if (e?.preventDefault) {
            e.preventDefault(); // Only call preventDefault if e exists
        }
        try {
            if(isLoader){
                swal.fire({
                    titleText           : 'Loading',
                    text                : 'Please Wait...',
                    showConfirmButton   : false,
                    allowOutsideClick   : false,
                    onOpen: () => {
                        swal.showLoading();
                    }
                });
            }
            
            const spouseArr = groupDetails.Spouse == 'Y' ? [spouseDetails] : []
            // user
            const userList = await Promise.all([primaryDetails, ...spouseArr, ...memberList].map(async (m, i) => {
                let profilePhotoBlob;
                
                switch (m.MemberType) {
                    case 'Primary':
                        profilePhotoBlob = primaryPhotoBlob;
                        break;
                    case 'Spouse':
                        profilePhotoBlob = spousePhotoBlob;
                        break;
                    default:
                        const index = m.MemberType == "Child" && groupDetails.Spouse == "Y" ? 2  : 1
                        profilePhotoBlob = memberPhotoBlobs.find(f => f?.Index === (i - index))?.newValue || ''; 
                }
                let userPhoto = '';
                if (profilePhotoBlob) {
                    const res = await uploadPublicFile(profilePhotoBlob, 'User');
                    userPhoto = res.fileName;
                }else{
                    if(m.ProfilePhoto) userPhoto = m.ProfilePhoto.split('/User/')[1];
                }
            
                return {
                    ID:             m?.ID,
                    UserID:         m?.UserID || 0,
                    MemberType:     m.MemberType,
                    FirstName:      m.FirstName,
                    MiddleName:     m.MiddleName,
                    LastName:       m.LastName,
                    DOB:            m?.DOB,
                    Gender:         m.Gender,
                    Email:          m.Email,
                    Mobile:         m.Mobile,
                    BloodGroup:     m.BloodGroup,
                    AadharCardNo:   m.AadharCardNo,
                    School:         m.School,
                    ProfilePhoto:   userPhoto
                };
            }));
            
            // documents
            let documentList = [];
            for (const m of document) {
                let userDoc = '';
                //Call upload API -->
                if(m.Document && m.Document != ''){
                    const res = await uploadPrivateFile(m.Document, 'Document');
                    userDoc = res.fileName;            
                }else{
                    if(m.FileName) userDoc = m.FileName;
                }

                documentList.push({
                    ID:              m?.ID,
                    DocumentType:    m.DocumentType,
                    FileName:        userDoc
                })
            };

            // admin
            const photoBlobs = [
                { blob: accountsPhotoBlob, key: 'AccountSignature' },
                { blob: membershipPhotoBlob, key: 'MembershipSignature' },
                { blob: adminPhotoBlob, key: 'AdminSignature' },
                { blob: presidentPhotoBlob, key: 'PresidentSignature' }
            ];

            const uploadPromises = photoBlobs.map(async ({ blob, key }) => {
                if (blob) {
                    const res = await uploadPublicFile(blob, 'User');
                    adminDetails[key] = res.fileName;
                }else{
                    if(adminDetails[key]) adminDetails[key] = adminDetails[key].split('/User/')[1];

                }
            });
            
            await Promise.all(uploadPromises);
             
            // group
            if (signaturePhotoBlob) {
                const res = await uploadPublicFile(signaturePhotoBlob, 'User');
                groupDetails.MemberSignature = res.fileName;
            }else{
                if(groupDetails.MemberSignature) groupDetails.MemberSignature = groupDetails.MemberSignature.split('/User/')[1];

            };
            let pdfLink = ''
            if(accessDetails.AdminType == 'President' && !!adminDetails.PresidentSignature){
                if(isLoader) swal.close();
                pdfLink = await downloadPDF(e, true);
            };
            await postOnboardingData(userList, groupDetails, adminDetails, documentList, [reference1Details, reference2Details], paymentList, accessDetails, formValidList.length > 0 ? "N" : "Y", pdfLink)
            if(isLoader) swal.close();
            
            if (!!adminDetails.PresidentSignature) {
                setTimeout(() => {
                    window.open(`https://gbgc.golfez.in/user-onboarded?userName=${primaryDetails.FirstName} ${primaryDetails.LastName}&type=${groupDetails.MembershipType}`, '_blank', 'noopener');
                    swal.fire({
                        icon: 'success',
                        titleText: 'User has been Onboarded Successfully.',
                        showConfirmButton: false,
                        timer: 3000,
                       
                    });
                }, 300); // Small delay to ensure previous swal instances are closed
            }
            
            setAccountsPhotoBlob(null);   
            setMembershipPhotoBlob(null);
            setAdminPhotoBlob(null);  
            setPresidentPhotoBlob(null);
            setMemberPhotoBlobs(Array(groupDetails?.Child).fill(null));
            setSpousePhotoBlob(null);
            setSignaturePhotoBlob(null);
            setPrimaryPhotoBlob(null);

            fetchOnboardingDetails(urlID, urlUserID, isLoader);
                
        } catch (err) {
            swal.fire({
                icon                  : 'error',
                titleText             : 'Error!',
                text                  : err.message.includes("Mobile Number already exist") ? "Mobile Number already exist" : err.message,
                buttonsStyling        : false,
                confirmButtonClass    : 'btn btn-brand',
            }); 
        }
    };

    const openDocument = async (d) => {
        if(!!d?.FileName){
            const ext = helper.getFileExt(d?.FileName)
            const name = (d.DocumentType + '.' + ext).replace(/ /g, '_');
            helper.downloadAndOpenFileHandler('Document', d.FileName, name); 
        }
    };

    ///////////////////////// Button Validation /////////////////////////

    const getIncompleteFields = (paymentList, adminDetails) => {
        return [
            !paymentList.length && 'Please fill payment info, ',
            !adminDetails.AccountDate && 'Date, ',
            !adminDetails.GST && 'GST, ',
            !adminDetails.TotalCheques && 'Total Cheques, ',
            !(adminDetails.AccountSignature || !!accountsPhotoBlob) && 'Signature'
        ].filter(Boolean); // removes empty strings
    };
    
    const isDisabled = () => {
        const adminID = accessDetails.AdminID;
        const incompleteFields = getIncompleteFields(paymentList, adminDetails);
        const conditions = [
            { id: 7, blob: accountsPhotoBlob, signature: adminDetails.AccountSignature },
            { id: 8, blob: membershipPhotoBlob, signature: adminDetails.MembershipSignature },
            { id: 5, blob: adminPhotoBlob, signature: adminDetails.AdminSignature },
            { id: 28, blob: presidentPhotoBlob, signature: adminDetails.PresidentSignature },
        ];
        return conditions.find(f => (f.id == adminID &&  !(f.signature || f.blob) || (adminID == 7 && incompleteFields.length > 0)))
 
    };
    
    const isAdminDisabled = (adminID, adminDetails, paymentList) => {
        const incompleteFields = getIncompleteFields(paymentList, adminDetails);
        
        return (adminID == 0 && formValidList.length > 0) ||
               (adminID == 7 && (accessDetails.AdminType == 'Accounts' || incompleteFields.length > 0)) ||
               (adminID == 8 && accessDetails.AdminType == 'Membership') ||
               (adminID == 5 && accessDetails.AdminType == 'Admin') ||
               (adminID == 28 && accessDetails.AdminType == 'President');
    };
    
    const getButtonText = (adminID, adminDetails) => {
        if (adminID == 0) return "Save & Submit";
        if ((adminID == 7 && accessDetails.AdminType != 'Accounts') ||
            (adminID == 8 && accessDetails.AdminType != 'Membership') ||
            (adminID == 5 && accessDetails.AdminType != 'Admin') ||
            (adminID == 28 && accessDetails.AdminType != 'President')) {
            return "Save";
        }
        return "Approve";
    };

    const updateBackgroundImageToBase64 = async (element) => {
        const elementsWithBackground = element.querySelectorAll('*');
      
        for (let elem of elementsWithBackground) {
          const backgroundImage = getComputedStyle(elem).backgroundImage;
          const regex = /url\(["']?(.*?)["']?\)/;
          const match = regex.exec(backgroundImage);
      
          if (match && match[1] && !match[1].startsWith('data:')) {
            const imageUrl = match[1].replace(/['"]/g, '');
      
            try {
              // Make the API call to your backend to get the Base64 image
                
              const data = await convertImageToBase64(imageUrl);
              if (data.base64Image) {
                // Set the Base64 image as the background
                elem.style.backgroundImage = `url(${data.base64Image})`;
              } else {
                console.error('No Base64 image received');
              }
            } catch (error) {
              console.error(`Error fetching or converting image URL "${imageUrl}" to Base64:`, error);
      
              // Fallback to a placeholder or default image
              const placeholder = 'data:image/png;base64,...'; // Add a default Base64 image here
              elem.style.backgroundImage = `url(${placeholder})`;
            }
          }
        }
      
        return element;
    };
       
    const downloadPDF = async (e, upload = false) => {
        e.preventDefault();
    
        const tooltipElement = tooltipRef.current;
    
        // Hide the tooltip element temporarily
        if (tooltipElement) {
            tooltipElement.style.display = 'none';
        }
    
        const element = printRef.current;
    
        if (element) {
            try {
                swal.fire({
                    titleText: 'Loading',
                    text: 'Please Wait...',
                    showConfirmButton: false,
                    onOpen: () => {
                        swal.showLoading();
                    }
                });
    
                // Update background images to Blob URLs
                const updatedElement = await updateBackgroundImageToBase64(element);
    
                const canvas = await html2canvas(updatedElement, {
                    scrollY: -window.scrollY,
                    scale: 1.5,
                    backgroundColor: '#ffffff',
                    useCORS: true, // Ensure cross-origin images are handled
                    logging: false,
                });
    
                // Convert the canvas to an image data URL
                const imgData = canvas.toDataURL('image/jpeg');
    
                // Calculate PDF dimensions
                const pdfWidth = 595.28; // Standard A4 width in points
                const imgWidth = pdfWidth;
                const imgHeight = (canvas.height * pdfWidth) / canvas.width;
    
                // Create a new PDF instance
                const pdf = new jsPDF('p', 'pt', [pdfWidth, imgHeight]);
                pdf.addImage(imgData, 'JPEG', 0, 0, imgWidth, imgHeight);
    
                // Restore the hidden tooltip element
                if (tooltipElement) {
                    tooltipElement.style.display = 'block';
                }
    
                if (upload) {
                    // Generate a Blob for the PDF and upload it
                    const pdfBlob = pdf.output('blob');
                    const res = await uploadPublicFile(pdfBlob, 'Document');
                    swal.close();
                    return res.fileName;
                } else {
                    // Save the PDF locally
                    pdf.save('download.pdf');
                }
    
                swal.close();
    
            } catch (error) {
                console.error('Error generating PDF:', error);
                swal.close();
            }
        } else {
            console.error("The printRef element is not found");
        }
    };
    
    return (
        <>
            <div ref={printRef} className={`container inputMemberForm`} style={{width: '1000px'}}>
                <form className={`form-horizontal onBoardUser ${(groupDetails.IsSubmit == "Y" && accessDetails.AdminID == 0) || (turn == 'President') ? 'disabled-overlay' : ''}`}>
                    {/* Primary */}
                    <PrimaryForm 
                        setSignatureImg={props.setSignatureImg} 
                        primaryDetails={primaryDetails} 
                        updatePrimaryDetails={updatePrimaryDetails}
                        setPrimaryPhotoBlob={setPrimaryPhotoBlob}
                        primaryPhotoBlob={primaryPhotoBlob}
                        setSignaturePhotoBlob={setSignaturePhotoBlob}
                        signaturePhotoBlob={signaturePhotoBlob}
                        groupDetails={groupDetails}
                        updateGroupDetails={updateGroupDetails}
                        panError={panError}
                        accessDetails={accessDetails}
                    />
                    
                    {/* Spouse */}

                    {groupDetails.Spouse == "Y" && (
                        <MemberForm 
                            memberDetails={spouseDetails} 
                            updateMemberDetails={updateSpouseDetails}
                            setMemberPhotoBlob={setSpousePhotoBlob}
                            memberPhotoBlob={spousePhotoBlob}
                            memberType={"Spouse Details"}
                            pageCount={2}
                        />  
                    )}

                    {/* Child Details */}

                    {Array.from({ length: groupDetails?.Child }, (_, index) => (
                        <MemberForm
                            key={index}
                            memberDetails={memberList[index]}
                            updateMemberDetails={(key, newValue) => updateArrayState(setMemberList, index, key, newValue)}
                            setMemberPhotoBlob={(newBlob) => updateArrayStatePhoto(setMemberPhotoBlobs, index, newBlob)}
                            memberPhotoBlob={memberPhotoBlobs[index]?.newValue}
                            memberType={`Child-${index + 1} Details`}
                            pageCount={index + 3}
                        />
                    ))}

                    {/* Document Upload */}
                    <div className="panel panel-paper">
                        <div className="panel-body form-onboarding">
                            <div className="panel-heading text-center" style={{marginBottom: '20px'}}>
                                {/* Logo and club name */}
                                <img className="img-responsive img-login margin-b10" src={bannerLogo} alt="" style={{height: '80px'}}/>
                                <h3 className="panel-title mb-0 padding-t0"><strong>THE GAEKWAD BARODA GOLF CLUB, MEMBERSHIP AGREEMENT</strong></h3>
                            </div>
                            <fieldset className="child-3">
                                <legend> Document Upload (<small>e.g. Pan Card, Aadhaar Card</small>)</legend>
                                <div className="text-center">
                                    <button type="button" className="btn btn-default upload-button" style={{width: "170px", height: "35px" }} onClick={() => setAddDocument(true)}> Upload Document</button>
                                </div>
                                <div className="row margin-tb50">
                                    {document.length > 0 && document.map((d, i) => (
                                        <div className="col-12 col-sm-3 padding-10 text-center" key={i}>
                                            {/* Edit button */} 
                                            <div className="edit-doc" onClick={() => setEditDocument({...d, Index: i})}>
                                                <div className="pencil-container">
                                                    <i className="fa fa-pencil"></i>
                                                    <span className="add-text">Update</span>
                                                </div>
                                            </div>
                                            <img height="100px" width="100px" src={helper.getFileLogo(d?.Document?.name || d?.FileName)}   onClick={() => openDocument(d)}/>
                                            <h6>{d.DocumentType}</h6>
                                            <span className="doc__cancel" onClick={() => removeDocHandler(i)}>
                                                <div className="pencil-container">
                                                    <i className="fa fa-times"></i>
                                                    <span className="add-text">Delete</span>
                                                </div>
                                            </span>
                                        </div>
                                    ))}
                                </div>
                            </fieldset>
                        </div>
                        <FormFooter num={groupDetails.Spouse == "Y" ? (1 + groupDetails.Child + 2) : groupDetails.Child + 2} />
                    </div>
                    {/* REFERENCES */}
                    <Reference 
                        reference1Details={reference1Details} 
                        reference2Details={reference2Details} 
                        updateReference1Details={updateReference1Details}
                        updateReference2Details={updateReference2Details}
                        count={groupDetails.Spouse == "Y" ? (1 + groupDetails.Child + 3) : groupDetails.Child + 3}
                    />
                    {/* Forms */}
                    {agreementFromList.length > 0 && agreementFromList.map((m, i) => (
                        <div className="panel panel-paper" key={i}>
                            <div className="panel-body form-onboarding" >
                                <div className="panel-heading text-center"  >
                                    {/* Logo and club name */}
                                    <img className="img-responsive img-login margin-b10" src={bannerLogo} alt="" style={{height: '80px'}}/>
                                    <h3 className="panel-title mb-0 padding-t0"><strong>THE GAEKWAD BARODA GOLF CLUB, MEMBERSHIP AGREEMENT</strong></h3>
                                </div>
                                <fieldset className="child-3" style={{marginTop: '20px'}}>
                                    <legend style={{marginBottom: '10px'}}> </legend>
                                    {i == 0 && <div style={{textAlign: "right"}}><h5 className="margin-tb0"><strong>Form No:</strong> {groupDetails?.ID || ''}</h5></div>}
                                    <div dangerouslySetInnerHTML={{ __html: m }} />
                                </fieldset>
                            </div>
                            <FormFooter num={groupDetails.Spouse == "Y" ? 1 + groupDetails.Child + 4 + i : groupDetails.Child + 4 + i} />
                        </div>
                    ))};
 
                    {/* Nomination */}
                    <NominationForm 
                        setAccountsPhotoBlob={setAccountsPhotoBlob}
                        accountsPhotoBlob={accountsPhotoBlob}
                        setMembershipPhotoBlob={setMembershipPhotoBlob}
                        membershipPhotoBlob={membershipPhotoBlob}
                        setAdminPhotoBlob={setAdminPhotoBlob}
                        adminPhotoBlob={adminPhotoBlob}
                        setPresidentPhotoBlob={setPresidentPhotoBlob}
                        presidentPhotoBlob={presidentPhotoBlob}
                        groupDetails={groupDetails}
                        updateGroupDetails={updateGroupDetails}
                        updateAdminDetails={updateAdminDetails}
                        adminDetails={adminDetails}
                        primaryDetails={primaryDetails}
                        accessDetails={accessDetails}
                        adminSignature={adminSignature}
                        count={groupDetails.Spouse == "Y" ? 1 + groupDetails.Child + 4 + 11 : groupDetails.Child + 4 + 11}

                    />

                    {/* Nomination */}
                    <MembershipPlan 
                        updateAdminDetails={updateAdminDetails}
                        adminDetails={adminDetails}
                        accountsPhotoBlob={accountsPhotoBlob}
                        paymentDetails={paymentDetails}
                        updatePaymentDetails={updatePaymentDetails}
                        handleAddClick={handleAddClick}
                        handleEditClick={handleEditClick}
                        handleDeleteClick={handleDeleteClick}
                        primaryDetails={primaryDetails}
                        paymentList ={paymentList}
                        editIndex ={editIndex}
                        accessDetails={accessDetails}
                        adminSignature={adminSignature}
                    />

                    {/* Annual Subscription plan*/}
                    <div className="panel panel-paper">
                        <div className="panel-body form-onboarding">
                            <fieldset className="child-3">
                                <AnnualSubscription
                                    primaryDetails={primaryDetails}
                                    adminDetails={adminDetails}

                                />
                            </fieldset>
                        </div>
                    </div>
                </form>
                <nav className="navbar navbar-default navbar-fixed-bottom" style={{boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.3)"}}>
                    <div className="container-fluid">
                        {turn == 'President' ? 
                            <button 
                                type="button" 
                                style={{ marginTop: '13.5px', display: 'flex', alignItems: 'center' }} 
                                className="btn btn-danger navbar-right margin-r10" 
                                onClick={downloadPDF}
                            >
                                <img 
                                    src={pdfLogo} 
                                    alt="PDF Icon" 
                                    style={{ width: '20px', height: '20px', marginRight: '8px' }} 
                                />
                                Download PDF
                            </button>                            : (
                            <form className={`navbar-form navbar-right ${(groupDetails.IsSubmit == "Y" && accessDetails.AdminID == 0) || (turn == 'President') ? 'disabled-overlay' : ''}`} role="search">
                                {formValidList.length > 0 ? (
                                    <>
                                        <button type="save" className="btn btn-default margin-r10" onClick={saveForm}>Save</button>
                                        <div className="tooltip-wrapper">
                                            <button
                                                type="submit"
                                                className="btn btn-primary"
                                                disabled={formValidList.length > 0}
                                            >
                                                Submit
                                            </button>
                                            <div className="tooltip-container">
                                                <div className="tooltip-inner">
                                                <p>Please complete the following fields:</p>
                                                <p>{formValidList.join(', ')}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ): (
                                    <div className="tooltip-wrapper">
                                        <span className="padding-r10">
                                            {(groupDetails.IsSubmit == "Y" && accessDetails.AdminID == 0) && 'Your form has been submitted successfully and is in the process of verifying'}
                                        </span>
                                        <button
                                            disabled={ isDisabled() }
                                            type="submit"
                                            className={`btn btn-${isAdminDisabled(accessDetails.AdminID, adminDetails, paymentList) ? "danger" : "primary"}`}
                                            onClick={saveForm}
                                        >
                                            {getButtonText(accessDetails.AdminID, adminDetails)}
                                        </button>

                                        {accessDetails.AdminID == 7 && isDisabled() && (
                                            <div className="tooltip-container">
                                                <div className="tooltip-inner">
                                                    <p>Please complete the following fields:</p>
                                                    <p>{getIncompleteFields(paymentList, adminDetails)}</p>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </form>
                        )
                        }
                    </div>
                </nav>
            </div>

            {cropperData && (
                <ImageCropper
                    show           = { cropperData }
                    header         = { cropperData.header }   
                    imageRatio     = { cropperData.imageRatio }
                    dataHandler    = { cropperData.dataHandler }
                    onDismissModal = {() => setCropperData(null) }
                />
            )}
            {signatureCropperData && (
                <ImageCropper
                    show           = { signatureCropperData }
                    header         = { signatureCropperData.header }   
                    imageRatio     = { signatureCropperData.imageRatio }
                    dataHandler    = { signatureCropperData.dataHandler }
                    onDismissModal = {() => setSignatureCropperData(null) }
                />
            )}
            {(addDocument || editDocument) && (
                <AddDocument
                    show={addDocument}
                    onDismissModal={setAddDocument}
                    editDocument={editDocument || ''}                                    
                    setEditDocument={setEditDocument}                                    
                    setDocumentDetails={setDocumentDetails}                                    
                />
            )}
        </>
    );
};

export default MembershipForm;