import React, { useContext, useState } from 'react';
import './membershipPlan.css';
import { signatureContext } from "../../context/context";

const MembershipPlan = (props) => {

    const {updateAdminDetails, paymentDetails, adminDetails, updatePaymentDetails, handleAddClick, handleEditClick, handleDeleteClick, primaryDetails, paymentList, editIndex, accountsPhotoBlob, accessDetails } = props;

    const signatureImg = useContext(signatureContext)

    const [paymentType,                 setPaymentType]         = useState([{ value:  'Cheque', label: 'Cheque'}, { value:  'Card', label: 'Card'}, { value:  'Cash', label: 'Cash'}, { value:  'NEFT', label: 'NEFT'}, { value:  'RTGS', label: 'RTGS'}]);
 
    return (
        <div className={`panel panel-paper ${accessDetails.AdminID == 0 ? 'disabled-overlay' : ''}`}  >
            <div className="panel-body form-onboarding" >
                <fieldset className="spouse margin-tb60" style={{border: '2px solid black'}}>
                    <div>
                        <div className="text-center margin-tb20" style={{ marginRight: '450px' }}>
                            <h6 className="boldText">MEMBERSHIP PLANS & FEE STRUCTURE # W.E.F. 01.06.2020</h6>
                            <h6 className="boldText"># ALL BELOW PLANS ARE FOR TENURE OF 5 / 3 / 1 YEARS</h6>
                            <h6 className="planTitle margin-tb20"># FIGURES STATED IN INDIAN RUPEES (INR)</h6>
                        </div>
                        <table className="smaller-table" border="1">
                            <thead style={{ backgroundColor: 'yellow' }}>
                                <tr>
                                    <th>MEMBERSHIP PLANS</th>
                                    <th>PARTICULARS</th>
                                    <th>5 YEARS</th>
                                    <th>3 YEARS</th>
                                    <th>1 YEAR - INDIVIDUAL - (WITH GOLF)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td rowSpan="4" className="boldText">WITHOUT GOLF FAMILY</td>
                                    <td>ENTRANCE FEE</td>
                                    <td>100000</td>
                                    <td>70000</td>
                                    <td>100000</td>
                                </tr>
                                <tr>
                                    <td>GST 18%</td>
                                    <td>18000</td>
                                    <td>12600</td>
                                    <td>18000</td>
                                </tr>
                                <tr>
                                    <td>SECURITY DEPOSIT</td>
                                    <td>20000</td>
                                    <td>20000</td>
                                    <td>10000</td>
                                </tr>
                                <tr>
                                    <td className="boldText">TOTAL INR</td>
                                    <td className="boldText">138000</td>
                                    <td className="boldText">102600</td>
                                    <td className="boldText">128000</td>
                                </tr>
                                <tr>
                                    <td rowSpan="4" className="boldText">WITH GOLF FAMILY</td>
                                    <td>ENTRANCE FEE</td>
                                    <td>200000</td>
                                    <td>150000</td>
                                    <td>125000</td>
                                </tr>
                                <tr>
                                    <td>GST 18%</td>
                                    <td>36000</td>
                                    <td>27000</td>
                                    <td>22500</td>
                                </tr>
                                <tr>
                                    <td>SECURITY DEPOSIT</td>
                                    <td>25000</td>
                                    <td>25000</td>
                                    <td>10000</td>
                                </tr>
                                <tr>
                                    <td className="boldText">TOTAL INR</td>
                                    <td className="boldText">261000</td>
                                    <td className="boldText">202000</td>
                                    <td className="boldText">157500</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="smaller-table" style={{width: "48%"}} border="1">
                            <thead style={{ backgroundColor: 'yellow' }}>
                                <tr>
                                    <th>CORPORATE PLANS</th>
                                    <th>PARTICULARS</th>
                                    <th>5 YEARS</th>
                                    <th>3 YEARS</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td rowSpan="4" className="boldText">CORPORATE WITHOUT GOLF - 2 NOMINEES (WITH FAMILY)</td>
                                    <td>ENTRANCE FEE</td>
                                    <td>200000</td>
                                    <td>140000</td>
                                </tr>
                                <tr>
                                    <td>GST 18%</td>
                                    <td>36000</td>
                                    <td>25200</td>
                                </tr>
                                <tr>
                                    <td>SECURITY DEPOSIT</td>
                                    <td>40000</td>
                                    <td>40000</td>
                                </tr>
                                <tr>
                                    <td className="boldText">TOTAL INR</td>
                                    <td className="boldText">276000</td>
                                    <td className="boldText">205200</td>
                                </tr>
                                <tr>
                                    <td rowSpan="4" className="boldText">CORPORATE WITH GOLF - 2 NOMINEES (WITH FAMILY)</td>
                                    <td>ENTRANCE FEE</td>
                                    <td>400000</td>
                                    <td>300000</td>
                                </tr>
                                <tr>
                                    <td>GST 18%</td>
                                    <td>72000</td>
                                    <td>54000</td>
                                </tr>
                                <tr>
                                    <td>SECURITY DEPOSIT</td>
                                    <td>50000</td>
                                    <td>50000</td>
                                </tr>
                                <tr>
                                    <td className="boldText">TOTAL INR</td>
                                    <td className="boldText">522000</td>
                                    <td className="boldText">404000</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="info-container">
                        <h6 className="boldText"># OVER AND ABOVE THE MEMBERSHIP FEE, AN ANNUAL SUBSCRIPTION WILL ALSO BE APPLIED FOR USAGE OF THE FACILITIES (REFER CURRENT ANNUAL SPORTS PLAN)</h6>
                        <h6 className="boldText"># CORPORATES CAN SUBSCRIBE FOR ADDITIONAL NOMINEES AFTER PAYING PRO-RATA ADDITIONAL CHARGES.</h6>
                        <h6 className="boldText"># NOMINEE CHANGE ENTAILS A FEE OF INR 2,000.</h6>
                        <h6 className="boldText"> # INTEREST FREE DEPOSITS WILL BE RETURNED ON THE COMPLETION OF 60 / 36 / 12 MONTHS FROM THE DATE OF RECEIPT OF EACH INSTALLMENT & NOT ON COMPLETION OF MEMBERSHIP.</h6>
                        <h6 className="boldText"># ALL POST DATED CHEQUES TO BE SUBMITTED ALONG WITH THE MEMBERSHIP FORM.</h6>
                    </div>
                    <div className={`form-container ${accessDetails.AdminID != 7 && accessDetails.AdminID != 8 ? 'disabled-overlay' : ''}`}>
                        <div className="row">
                            <div className="col-sm-4">
                                <div className="membership-plan-group">
                                    <label htmlFor="planSelected">PLAN SELECTED #</label>
                                    <input type="text" id="planSelected" name="planSelected" className="form-control text-center" value={adminDetails.PlanSelected || ''} onChange={(event) => updateAdminDetails('PlanSelected',  event.target.value)}/>
                                </div>
                            </div>
                            <div className="col-sm-5">
                                <div className="membership-plan-group">
                                    <label htmlFor="memberName">NAME OF MEMBER #</label>
                                    <input type="text" className="form-control wide-input text-center" id="inputFirstName" disabled value={ primaryDetails?.FirstName && primaryDetails?.LastName ?  primaryDetails.FirstName + ' ' + primaryDetails.LastName : ''} />
                                </div>
                            </div>
                            <div className="col-sm-3">
                                <div className="membership-plan-group">
                                    <label htmlFor="signature">SIGNATURE </label>
                                        {(!!signatureImg) ? (
                                            <div className="avatar__holder"
                                                style={{
                                                    backgroundImage: typeof signatureImg === 'object' && signatureImg instanceof Blob
                                                        ? `url(${URL.createObjectURL(signatureImg)})`
                                                        : `url(${signatureImg})`,
                                                    width: "130px",
                                                    height: "30px",
                                                    marginLeft: '23px',
                                                    backgroundSize: "cover",
                                                    backgroundPosition: "center"
                                                }}
                                            ></div>
                                        ) : (
                                            <div style={{ width: "130px", height: "30px"}}></div>
                                        )}                                     {/* {(signatureImg) ? 
                                        <div className="signature-text"style={{ width: "130px", height: "35px", border: '1px solid #ddd',borderRadius: '4px',display: 'flex',alignItems: 'center',justifyContent: 'center',textAlign: 'center',overflow: 'hidden',fontSize: '1rem',marginLeft: '19px'}}>
                                            <strong>{signatureImg}</strong>
                                        </div>
                                        : <div style={{ width: "130px", height: "30px"}}></div>} */}
                                </div>
                            </div>
                        </div>
                       <div className="panel panel-paper padding-50 margin-tb30">
                            <div className="row mb-3">
                                <div className="col-md-2 ">
                                    <select
                                        value={paymentDetails?.Type || ''}
                                        onChange={(event) => updatePaymentDetails('Type', event.target.value)}
                                        style={{width: "100%"}}
                                    >
                                        <option defaultValue={'Select Option'}>Select Option</option>
                                        {paymentType.map((type, index) => (
                                            <option key={index} value={type.value}>{type.label}</option>
                                        ))}
                                    </select>
                                </div>
                                {paymentDetails?.Type && paymentDetails?.Type != 'Select Option' && (
                                    <>
                                        {paymentDetails?.Type != 'Cash' && (
                                            <div className={`${!['Card', 'Cash', 'RTGS'].includes(paymentDetails?.Type) ? 'col-md-2' : 'col-md-3'}`}>
                                                <input
                                                    type="text"
                                                    name="referenceId"
                                                    value={paymentDetails.ReferenceNumber || ''}
                                                    onChange={(event) => updatePaymentDetails('ReferenceNumber', event.target.value)}
                                                    placeholder="Reference Number"
                                                    className="form-control"
                                                />
                                            </div>
                                        )}
                                        <div className={`${!['Card', 'Cash', 'RTGS'].includes(paymentDetails?.Type) ? 'col-md-3' : paymentDetails?.Type == 'Cash' ? 'col-md-4' : 'col-md-3'}`}>
                                            <input
                                                type="date"
                                                name="date"
                                                value={ paymentDetails.Date ? moment(paymentDetails.Date).format('YYYY-MM-DD') : '' }
                                                onChange={(event) => updatePaymentDetails('Date', event.target.value)}
                                                className="form-control"
                                            />
                                        </div>
                                        <div className={`${paymentDetails?.Type == 'Cash' ? 'col-md-4' : 'col-md-2'}`}>
                                            <input
                                                type="text"
                                                name="inr"
                                                value={paymentDetails.INR || ''}
                                                onChange={(event) => updatePaymentDetails('INR', event.target.value)}
                                                placeholder="INR"
                                                className="form-control"
                                            />
                                        </div>
                                        {!['Card', 'Cash', 'RTGS'].includes(paymentDetails?.Type) && (
                                            <div className="col-md-2">
                                                <input
                                                    type="text"
                                                    name="bank"
                                                    value={paymentDetails.Bank || ''}
                                                    onChange={(event) => updatePaymentDetails('Bank', event.target.value)}
                                                    placeholder="Bank"
                                                    className="form-control"
                                                />
                                            </div>
                                        )}
                                        <div className="col-md-1">
                                            <button className="btn btn-primary" onClick={handleAddClick}>
                                                {editIndex !== null ? "Update" : "Add"}
                                            </button>
                                        </div>
                                    </>
                                )}
                            </div>
                            <table className=" table table-striped table-hover margin-t30" >
                                <thead>
                                    <tr>
                                        <th>Payment Type</th>
                                        <th>Reference Number</th>
                                        <th>Date</th>
                                        <th>INR</th>
                                        <th>Bank</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {paymentList.length > 0 ? (paymentList.map((data, index) => (
                                        <tr key={index}>
                                            <td>{data.Type}</td>
                                            <td>{data.ReferenceNumber}</td>
                                            <td>{moment(data.Date).format('DD-MM-YYYY')}</td>
                                            <td>{data.INR}</td>
                                            <td>{data.Bank}</td>
                                            <td>
                                                <button className="btn btn-warning margin-r5 btn-xs" onClick={(e) => handleEditClick(index, e)}>Edit</button>
                                                <button className="btn btn-danger btn-xs" onClick={(e) => handleDeleteClick(index, e)}>Delete</button>
                                            </td>
                                        </tr>
                                    ))):(
                                        <tr>
                                            <td colSpan={6}> 
                                                <div> 
                                                    <h5 className="text-center text-muted"> No data..! </h5> 
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="membership-plan-group">
                                    <label htmlFor="numCheques">NO. OF CHEQUES #</label>
                                    <input type="text" id="numCheques" name="numCheques" className="form-control text-center" defaultValue={paymentList.length || ''} disabled/>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="membership-plan-group">
                                    <label htmlFor="gst">GST (18%) INR #</label>
                                    <input type="text" id="gst" name="gst" className="form-control text-center"  value={adminDetails.GST || ''} onChange={(event) => updateAdminDetails('GST',  event.target.value)}/>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="membership-plan-group">
                                    <label htmlFor="formReceivedBy">FORM RECEIVED BY #</label>
                                   {(accountsPhotoBlob || adminDetails?.AccountSignature) && (
                                        <div className="avatar__holder margin-b20 " style={{ backgroundImage: `url(${accountsPhotoBlob ? URL.createObjectURL(accountsPhotoBlob) : adminDetails.AccountSignature && adminDetails.AccountSignature })`, marginLeft: "-5px", backgroundSize: "cover", backgroundPosition: "center" }}></div>
                                    )}
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="membership-plan-group">
                                    <label htmlFor="accountDept">ACCOUNT DEPT #</label>
                                    {(accountsPhotoBlob || adminDetails?.AccountSignature) && (
                                        <div className="avatar__holder margin-b20 " style={{ backgroundImage: `url(${accountsPhotoBlob ? URL.createObjectURL(accountsPhotoBlob) : adminDetails.AccountSignature && adminDetails.AccountSignature })`, marginLeft: "-5px", backgroundSize: "cover", backgroundPosition: "center" }}></div>
                                    )}                         
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="membership-plan-group">
                                    <label htmlFor="formReceivedBy">REMARK</label>
                                    <textarea
                                        id={`txtRemark`}
                                        className="form-control important-height"
                                        style={{ width: '100%', height: '40px' }}
                                        value={adminDetails.Remark || ''}
                                        onChange={(e) => updateAdminDetails('Remark', e.target.value)}
                                        
                                    ></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>
        </div>
    );
};


export default MembershipPlan;
