import React, { useEffect, useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';

const SignatureModal = (props) => {
    const { setSignaturePhotoBlob, openSignatureCropper, adminSignature, updateAdminDetails, signatureType } = props;
    const sigPad = useRef(null);

    // State to manage the green tick
    const [selectedSignature, setSelectedSignature] = useState(null);

    useEffect(() => {
        $('#modalSignature').modal({
            backdrop: 'static',
        });
        $('#modalSignature').on('hidden.bs.modal', function () {
            $('.modal').hasClass('show') && $('body').addClass('modal-open');
            props.onDismissModal(false);
        });
    }, []);

    function base64ToBlob(base64, type = 'image/jpeg') {
        const byteCharacters = atob(base64.split(',')[1]);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        return new Blob([byteArray], { type: type });
    }

    // Save signature
    const saveSignature = (e) => {
        e.preventDefault();
        if (selectedSignature) {
            updateAdminDetails(signatureType, selectedSignature);  
        }else if (sigPad.current) {
            // Get the signature data in PNG format
            const pngData = sigPad.current.toDataURL('image/png');

            // Convert the base64 PNG data to a Blob
            const blob = base64ToBlob(pngData);

            // Set the Blob for use in parent component
            setSignaturePhotoBlob(blob);

            // Close the modal
        }
        $('#modalSignature').modal('hide');
    };

    // Clear signature pad
    const clearSignature = (e) => {
        e.preventDefault();
        if (sigPad.current) {
            sigPad.current.clear();
            setSelectedSignature(null); // Remove the green tick on clear
        }
    };
 

    const toggleSignatureSelection = (signature) => {
        if (selectedSignature === signature) {
            setSelectedSignature(null); // Deselect if already selected
        } else {
            setSelectedSignature(signature); // Select the clicked signature
        }
    };
    

    return (
        <div id="modalSignature" className="modal fade" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header padding-b0">
                        <button type="button" className="close" onClick={() => $('#modalSignature').modal('hide')}>
                            <span>&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div
                            className="signature-container"
                            style={{
                                height: '300px',
                                width: '100%',
                                textAlign: 'center',
                                backgroundColor: '#b7b7b74d',
                                padding: '25px',
                                position: 'relative',
                                pointerEvents: selectedSignature ? 'none' : 'auto',
                            }}
                        >
                            <SignatureCanvas
                                ref={sigPad}
                                penColor="black"
                                backgroundColor="white"
                                canvasProps={{
                                    width: 700,
                                    height: 240,
                                    style: {
                                        border: '2px dotted grey',
                                    },
                                }}
                                minWidth={1}
                                maxWidth={3}
                                throttle={16}
                                
                            /> 
                        </div>
                        <h5 className="modal-title" style={{ textAlign: 'center' }}>
                            Sign within the dotted lines
                        </h5>
                        {!!adminSignature && Array.isArray(adminSignature) && adminSignature.length > 0 && (
                            <>
                                <h6 className="modal-title" style={{ textAlign: 'center' }}>
                                    OR
                                </h6>
                                <hr className='margin-t10'/>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        flexWrap: 'wrap',
                                        gap: '10px', // Space between signature options
                                    }}
                                >
                                    {adminSignature.map((signature, index) => (
                                        <div
                                            key={index}
                                            className="avatar__holder"
                                            style={{
                                                backgroundImage: `url(${signature.Signature})`,
                                                width: '165px', // 50% smaller width
                                                height: '55px', // 50% smaller height
                                                backgroundSize: 'cover',
                                                backgroundPosition: 'center',
                                                position: 'relative', // Enable positioning for the tick
                                                border: selectedSignature === signature.Signature ? '2px solid green' : '1px solid', // Highlight selected signature
                                                margin: '0 auto', // Center horizontally
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center', // Center in modal
                                                cursor: 'pointer', // Add pointer cursor
                                            }}
                                            onClick={() => toggleSignatureSelection(signature.Signature)}
                                        >
                                            {selectedSignature === signature.Signature && (
                                                <div
                                                    style={{
                                                        position: 'absolute',
                                                        top: '10%',
                                                        right: '-8%',
                                                        transform: 'translate(-50%, -50%)',
                                                        width: '19.5px', // 50% of the original 25px size
                                                        height: '19.5px',
                                                        backgroundColor: 'green',
                                                        borderRadius: '50%',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            color: 'white',
                                                            fontSize: '10px', // Adjusted font size for smaller tick
                                                            fontWeight: 'bold',
                                                        }}
                                                    >
                                                        ✓
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                                <h5 className="modal-title" style={{ textAlign: 'center', marginTop: '10px' }}>
                                    Click on a signature image to select it. Click again to deselect.
                                </h5>
                                <hr className='margin-tb10'/>
                            </>
                        )}
                    </div>
                    <div className="modal-footer">
                        <button className="btn btn-default upload-button margin-r10" onClick={openSignatureCropper} disabled={selectedSignature}>
                            Upload Signature
                        </button>
                        <button className="btn btn-default margin-r10" onClick={clearSignature}>
                            Clear
                        </button>
                        <button className="btn btn-primary" onClick={saveSignature}>
                            {selectedSignature ? "Add" : "Save"} Signature
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SignatureModal;
