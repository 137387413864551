import { getAuthState } from "../../assets/scripts/login-util";
import { axiosGet, axiosPost } from "./axiosFunctions";
 
const getOnboardingDataByGroupIDUrl                 = '/api/onboarding/getOnboardingDataByGroupID';
const postOnboardingDataUrl                         = '/api/onboarding/postOnboardingData';

const uploadPublicFileUrl                           = (folderName) => `/api/onboarding/uploadPublicFile/${folderName}`;
const uploadPrivateFileUrl                          = (folderName) => `/api/onboarding/uploadPrivateFile/${folderName}`;
const downloadFileUrl                               = (fileType, fileName) => `/api/onboarding/downloadFile/${fileType}/${fileName}`

const convertImageToBase64Url                       = '/api/onboarding/convertImageToBase64';


const privateFolder = ['Document'];
const publicFolder = ['User', 'Document'];

export const getOnboardingDataByGroupID = async (groupID, userID) => {   
    const response = await axiosGet(getOnboardingDataByGroupIDUrl, { headers: '', params: {groupID, userID}});
    return response;
};

export const postOnboardingData = async (userList, groupDetails, adminDetails, documentList, refList, paymentModeList, accessDetails, isSubmit, pdfLink) => {
    const response = await axiosPost(
        postOnboardingDataUrl,
        { userList, groupDetails, adminDetails, documentList, refList, paymentModeList, accessDetails, isSubmit, pdfLink },
        { headers: '' }
    );
    return response;
};

export const uploadPublicFile = async (file, folderName) => {
    if (!publicFolder.includes(folderName)) throw new Error('Unknown file type')
        
    const formData = new FormData()
    formData.append('file', file)
        
     const response = await axiosPost(
        uploadPublicFileUrl(folderName),
        formData,
        {
            // headers: { auth_token: `bearer ${token}`, "Content-Type":"multipart/form-data" },
            headers: {"Content-Type":"multipart/form-data"},
            onUploadProgress(progress) { console.log('file upload progress:', `${progress.loaded}/${progress.total}`)},
        }
    )
    return response
};

export const uploadPrivateFile = async (file, folderName) => {

    if (!privateFolder.includes(folderName)) throw new Error('Unknown file type')

    const formData = new FormData()
    formData.append('file', file)
    
    const response = await axiosPost(
        uploadPrivateFileUrl(folderName),
        formData,
        {
            headers: {"Content-Type":"multipart/form-data"},
            onUploadProgress(progress) { console.log('file upload progress:', `${progress.loaded}/${progress.total}`)},
        }
    )
    return response
};

export const downloadFile = async (fileType, fileName) => {
       
    if (!privateFolder.includes(fileType)) throw new Error('Unknown file type')
    const response = await axiosGet(
        downloadFileUrl(fileType, fileName),
        {
            headers: {"Content-Type":"multipart/form-data"},
        }
    )
    return response
};

export const convertImageToBase64 = async (imageUrl) => {
   
    const response = await axiosPost(
        convertImageToBase64Url,
        { imageUrl },
        { headers: ''} 
    );
    return response;
};